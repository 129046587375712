import React, { useState, useEffect } from 'react'
import { Container, Form, Modal, Button } from 'react-bootstrap'
import _ from "lodash"
import { isEmpty, filter, find, isArray, isString, get, indexOf } from "lodash"
import Select from 'react-select';
import { navigate } from "@reach/router";

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../DateRangeSelect/DateRangeSelect.scss';
import { format } from 'date-fns'

import { navigate as gatsbyNavigate } from "gatsby";
import MoreFilter from "../MoreFilter/MoreFilter";
import { connectRefinementList, RefinementList } from "react-instantsearch-dom"
import $ from 'jquery'
import { propertyTypes, priceminRangeResiSale, pricemaxRangeResiSale, priceminRangeResiRent, pricemaxRangeResiRent, bedroomsRange, resiSalesPropertyType, resiLettingsPropertyType } from "../../components/common/propertyUtils";
import SearchListBox from "../predictive-search/search-multiareas";
import AreaListBox from "./AreaList";
import '../SearchSelect/SearchSelect.scss';
import '../MoreFilter/MoreFilter.scss'
import { isIOS, isMacOs } from 'react-device-detect';


function PropertyFilterHolidayLets(props) {
    // Common Search Navigate
    const pyOptions = propertyTypes
    const onChangeSellRent = (e) => {
        gatsbyNavigate(`/${e.value}`)
    }
    // 
    // Search Filter States
    // 
    const [areaVal, setAreaVal] = useState('');
    const [calenderState, setCalenderState] = React.useState(false);
    const [guestState, setGuestState] = React.useState(false);
    const [closeState, setCloseState] = React.useState(false);
    const [adultCount, setAdultCount] = React.useState(0);
    const [childrenCount, setChildrenCount] = React.useState(0);
    const [infantCount, setInfantCount] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0);
    const [areaRefineList, setAreaRefineList] = useState([]);
    const [areasArr, setAreasArr] = useState([]);
    const [areasList, setAreasList] = useState([]);
    const [minpriceVal, setMinPriceVal] = useState('');
    const [maxpriceVal, setMaxPriceVal] = useState('');
    const [minbedroomVal, setMinBedroomVal] = useState('');
    const [maxbedroomVal, setMaxBedroomVal] = useState('');
    const [propertyval, setPropertyVal] = useState('');
    const [rentalperiod, setRentalPeriod] = useState('');
    const [bedVal, setBedVal] = useState('');
    const [minPriceList, setMinPriceList] = useState([]);
    const [maxPriceList, setMaxPriceList] = useState([]);
    const [minBedroomList, setMinBedroomList] = useState([]);
    const [maxBedroomList, setMaxBedroomList] = useState([]);
    const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
    const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);
    const [selectedMinBedroomValue, setSelectedMinBedroomValue] = useState(0);
    const [selectedMaxBedroomValue, setSelectedMaxBedroomValue] = useState(0);
    let defaultArea = "dubai";

    
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [startdateval, SetStartDateVal] = useState('')
    const [startdatestr, SetStartDateStr] = useState('')
    const [enddateval, SetEndDateVal] = useState('')
    const [enddatestr, SetEndDateStr] = useState('')

    // useEffect(() => {
    //     SetStartDateVal(formatDate(dateRange.startDate))
    //     SetStartDateStr(formatDate(dateRange.startDate))
    //     SetEndDateVal(formatDate(dateRange.endDate))
    //     SetEndDateStr(formatDate(dateRange.endDate))
    // }, [dateRange])

    const handleMultiAreaFun = (val) => {
        let areas = [];
        if (isEmpty(find(areasArr, val))) {
            areas = [...areasArr]
            areas.push(val)
            setAreasArr(areas)
        }
    }
    const removeArea = (area, clear = false) => {
        if (!clear) {
            let prevAreas = areasArr.filter(x => x.slug !== area)
            setAreaVal('');
            setAreasArr(prevAreas);
        } else {
            setAreasArr([]);
            setAreaVal('');
            setAreasList([]);
        }
    }



    // 
    // Show Hide More Filter
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // 
    ///// Price Filter Set /////
    // Min price
    const onChangeMinPrice = (e) => {
        setSelectedMinPriceValue(e.value);
    }
    // Min price

    // Max Price
    const onChangeMaxPrice = (e) => {
        setSelectedMaxPriceValue(e.value);
    }
    // Max Price

    // Min bedroom
    const onChangeMinBedroom = (e) => {
        setSelectedMinBedroomValue(e.value);
    }
    // Min bedroom

    // Sales price list
    var minPrice = [];
    var maxPrice = pricemaxRangeResiSale.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

    priceminRangeResiSale.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));

    if (minPrice.length > 0) {
    } else {
        minPrice.push(priceminRangeResiSale[0])
    }

    if (maxPrice.length > 0) {
    } else {
        maxPrice.push(pricemaxRangeResiSale[0])
    }
    // Sales price list

    // Lettings price list
    var minPriceLettings = [];
    var maxPriceLettings = pricemaxRangeResiRent.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

    priceminRangeResiRent.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPriceLettings.push(items));

    if (minPriceLettings.length > 0) {
    } else {
        minPriceLettings.push(priceminRangeResiRent[0])
    }

    if (maxPriceLettings.length > 0) {
    } else {
        maxPriceLettings.push(pricemaxRangeResiRent[0])
    }
    // Lettings price list

    // 
    useEffect(() => {
        // 

        // Page when load field in text boxes will same
        const url = typeof window !== 'undefined' ? window.location.href : ''
        let pathUri = url.split("/")
        for (let vi = 1; vi <= pathUri.length; vi++) {
            // check for area
            if (typeof pathUri[vi] === "undefined") {
                continue
            }
var stDate;
var edDate;
             // Check in
             if (pathUri[vi].indexOf("check-in-") >= 0) {
                setStartDate(formatDate(pathUri[vi].replace("check-in-", "")))
                setStartDisplayDate(formatDateDisplay(pathUri[vi].replace("check-in-", "")))
                stDate= formatDate(pathUri[vi].replace("check-in-", ""))
            }
            // Check in

                         // Check out
                         if (pathUri[vi].indexOf("check-out-") >= 0) {
                            setEndDate(formatDate(pathUri[vi].replace("check-out-", "")))
                            setEndDisplayDate(formatDateDisplay(pathUri[vi].replace("check-out-", "")))
                            edDate = formatDate(pathUri[vi].replace("check-out-", ""))
                        }
                        // Check out
                        setDateRange({
                            startDate: stDate ? new Date(stDate) : new Date(),
                            endDate: edDate ? new Date(edDate) : new Date(),
                            key: "selection",
                          })
            // Adults
            if (pathUri[vi].indexOf("-adults-or-more") >= 0) {
                setAdultCount(parseInt(pathUri[vi].replace("-adults-or-more", "")))
            }
            // Adults

            // Childrens
            if (pathUri[vi].indexOf("-childrens-or-more") >= 0) {
                setChildrenCount(parseInt(pathUri[vi].replace("-childrens-or-more", "")))
            }
            // Childrens
            if ((pathUri[vi].indexOf("-adults-or-more") >= 0) || (pathUri[vi].indexOf("-childrens-or-more") >= 0)) {
                setTotalCount((parseInt(adultCount)) + (parseInt(childrenCount)))
            }
        }
        // Page when load field in text boxes will same
    }, []);


    useEffect(() => {


        if (props.searchState?.refinementList?.search_areas) {
            let searchAreas = props.searchState.refinementList.search_areas;
            if (searchAreas !== defaultArea) {
                if (isString(searchAreas)) {
                    searchAreas = [searchAreas]
                }
                setAreaRefineList(searchAreas);
            }
        }
        //alert("test");
    }, [props.searchState?.refinementList])


    React.useEffect(() => {
        // if (amenities.length > 0) {
        //   for (var i = 0; i < amenities.length; i++) {
        //     $('.amenities-refine input[type="checkbox"][value="' + amenities[i] + '"]').prop('checked', 'checked');
        //   }
        // }
        if (props.searchState?.refinementList?.search_areas && areasList.length) {
            let search_areas = props.searchState.refinementList.search_areas;
            if (search_areas !== defaultArea) {
                let defaultRefineItems = []
                if (isString(search_areas)) {
                    search_areas = [search_areas]
                }
                search_areas.map((item) => {
                    let itemObj = find(areasList, ['slug', item])
                    if (!isEmpty(itemObj)) {
                        defaultRefineItems.push(itemObj)
                    }
                });

                setAreasArr(defaultRefineItems);
                setAreaRefineList(search_areas);
            }

        }
    }, [areasList, props.searchState?.refinementList?.search_areas])


    // 
    //  Submit Form on Mobile Sort
    // useEffect(() => {

    //     submitSearch();      

    // },[mobilesort])
    const HandleSort = (e) => {
        var searchFilterResults = 'sortby-' + e;
        navigate(searchFilterResults);
    }
    // Submit search
    const submitSearch = (e) => {
        setGuestState(false)
        setCalenderState(false)
        $('.price-filter-dropdown').removeClass("active");
        //console.log('aaaaaaaaaaaprops',props.searchState)
        var areaValue = []
        if (areaRefineList) {
            let areasToRefine = []
            // let areasForUrl = []
            areasArr.map((a) => {
                if (a) {
                    areasToRefine.push(a.slug)
                    // areasForUrl.push(a.slug)
                }
            })
            setAreaRefineList(areasToRefine)
            areaValue = areasToRefine
        }

        var adultsValue = adultCount
        var childrenValue = childrenCount

        var searchFilterResults = "";
        // console.log("areaValueareaValue", areaValue)
        // var keywordareaval = 'dubai';
        var keywordareaval = $(".areas-list-wrap .react-autosuggest__input").val();
        // console.log("areaValueareaValue", keywordareaval)
        if (isArray(areaValue) && areaValue.length == 0 && keywordareaval.length > 0) {
            areaValue.push(keywordareaval.replace(/\s+/g, '-'));
        }

        if (isArray(areaValue)) {
            if (areaValue.length > 0) {
                searchFilterResults = "/holiday-lets/in-" + areaValue.join("-and-") + "/";
            } else {
                searchFilterResults = "/holiday-lets/in-" + defaultArea + "/";
            }
        } else {
            searchFilterResults = "/holiday-lets/in-" + defaultArea + "/";
        }


        if (adultsValue > 0) {
            searchFilterResults += adultsValue + "-adults-or-more/";
        }

        if (childrenValue > 0) {
            searchFilterResults += childrenValue + "-childrens-or-more/";
        }

        if(startDate) {
            searchFilterResults += "check-in-"+ startDate +"/";
        }

        if(endDate) {
            searchFilterResults += "check-out-"+ endDate +"/";
        }


        if (props.sort_by) {
            searchFilterResults += 'sortby-' + props.sort_by;
        }

        navigate(searchFilterResults);
        setCloseState(false)
    }
    // Submit search

    useEffect(() => {
        setTotalCount(adultCount + childrenCount + infantCount)
    }, [adultCount, childrenCount, infantCount])

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
  
    const [startDisplayDate, setStartDisplayDate] = useState("")
    const [endDisplayDate, setEndDisplayDate] = useState("")
    const handleDateChange = range => {
        setDateRange(range.selection)
        const start = formatDate(range.selection.startDate)
        const end = formatDate(range.selection.endDate)
    
        const startDispaly = formatDateDisplay(range.selection.startDate)
        const endDispaly = formatDateDisplay(range.selection.endDate)
    
        setStartDate(start)
        setEndDate(end)
    
        setStartDisplayDate(startDispaly)
        setEndDisplayDate(endDispaly)
    
      }
      const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      })
      const formatDate = date => {
        const formatedDate = format(new Date(date), "yyyy-MM-dd")
        return formatedDate
      }
    const formatDateDisplay = date => {
        const formatedDate = format(new Date(date), "dd-MM-yyyy")
        return formatedDate
      }
    return (
        <>
            <div className='search-select'>
                <div className="d-none">
                    {(areaRefineList.length > 0) && (
                        <RefinementList
                            attribute="search_areas"
                            defaultRefinement={areaRefineList}
                        />
                    )}
                </div>

                <Form id="myForm" action="javascript:;" method="post" className='search-wrapper' onSubmit={submitSearch}>
                    <div className='location-search flex-grow-1'>
                        <div className={`${isMacOs || isIOS ? "Ios" : ""} filter-search-input-box`}>
                            <div className="dropdown areas-list-wrap">
                                <SearchListBox areaVal={areaVal} setAreaVal={setAreaVal} setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun} autosuggestInputProps={{ className: 'form-control react-autosuggest__input refine-autosuggest-input' }} />


                                {/* <Select className="react-select" classNamePrefix="react-select" defaultValue={optionsLocation[0]} options={optionsLocation} isSearchable={false} /> */}
                                {/* <i className="icon-location"></i> */}
                                {/* <Form.Control className='react-select' type="search" placeholder="Street, area or postcode" id="formBasicEmail" */}
                                {/* /> */}
                            </div>
                        </div>
                    </div>
                    <div className='rtl-block'>
                        <div className={calenderState ? 'calender-block d-none d-xl-block active' : 'calender-block d-none d-xl-block'}>

                            <div className='date-picker'>
                                <DateRange
                                    
                                    editableDateInputs={true}
                                    onChange={handleDateChange}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[dateRange]}
                                    weekdayDisplayFormat="EEEEE"
                                    dateDisplayFormat="dd-MM-yyyy"
                                    showClearDate={true}
                                    showDateDisplay={false}
                                    minDate={new Date()}
                                />
                                {/* <button className='clear-date' onClick={() => {
                                    setState([
                                    {
                                        startDate: new Date(),
                                        endDate: new Date(),
                                        key: 'selection'
                                    }
                                ]);
                                setDateRange({
                                    startDate: new Date(),
                                    endDate: new Date(),
                                    key: "selection",
                                  });
                                  setStartDisplayDate();setEndDisplayDate();setStartDate();setEndDate()}}>Clear Dates</button> */}
                            </div>
                            <div className='calender-btn date-range' onClick={() => {setCalenderState(prev => !prev);setGuestState(false)}}>
                                <div className={calenderState ? "datechoose active" : "datechoose"}>
                            {startDisplayDate ? 
    <>
    {startDisplayDate} to {endDisplayDate}
    </>
    : 
    <>Check in - Check out</>}
    </div>
                            </div>
                        </div>
                        <div className='guest-btn'>
                            <div className="guest-number d-none d-xl-flex" onClick={() => {setGuestState(prev => !prev);setCalenderState(false)}}>
                                {totalCount ? totalCount : null} Guests <i className={guestState ? "icon-dropdown active" : "icon-dropdown "}></i>
                            </div>
                            <div className={guestState ? "guest-dropdown d-block" : "guest-dropdown d-none"}>
                                <div className="guest-wrap">
                                    <div className="ltl-guest">
                                        <span className="guest-category">Adults</span>
                                        <span className="guest-description">(Ages 16 or above)</span>
                                    </div>
                                    <div className="rtl-guest">
                                        <div className="minus-btn" onClick={() => (adultCount > 0 && setAdultCount(adultCount - 1))}>-</div>
                                        <span className="btn-value">
                                            {adultCount}
                                        </span>
                                        <div className="plus-btn" onClick={() => setAdultCount(adultCount + 1)}>+</div>
                                    </div>
                                </div>
                                <div className="guest-wrap">
                                    <div className="ltl-guest">
                                        <span className="guest-category">Children</span>
                                        <span className="guest-description">(Ages 0-12 or above)</span>
                                    </div>
                                    <div className="rtl-guest">
                                        <div className="minus-btn" onClick={() => (childrenCount > 0 && setChildrenCount(childrenCount - 1))}>-</div>
                                        <span className="btn-value">
                                            {childrenCount}
                                        </span>
                                        <div className="plus-btn" onClick={() => setChildrenCount(childrenCount + 1)}>+</div>
                                    </div>
                                </div>
                                {/* <div className="guest-wrap">
                                    <div className="ltl-guest">
                                        <span className="guest-category">Infants</span>
                                        <span className="guest-description">(Under 2)</span>
                                    </div>
                                    <div className="rtl-guest">
                                        <div className="plus-btn" onClick={() => (infantCount > 0 && setInfantCount(infantCount - 1))}>-</div>
                                        <span className="btn-value">
                                            {infantCount}
                                        </span>
                                        <div className="minus-btn" onClick={() => setInfantCount(infantCount + 1)}>+</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className='btn-filter d-xl-none' onClick={() => setCloseState(true)}>
                            <i className='icon-filter d-md-none m-0'></i>
                            <i className='icon-filter-tab d-none d-md-block'></i>
                            <span className="d-none d-md-inline-block">More Filters</span>
                        </div>
                        <button className='search-btn'>
                            <i className='icon-search'></i>
                        </button>
                    </div>
                </Form>
            </div>
            <div className='reset-filter'>
                <a href={"/holiday-lets/in-dubai/"}>Reset Filters</a>
            </div>
            <AreaListBox areas={areasArr} removeArea={removeArea} />

            {
                closeState &&
                <Form id="myForm" action="javascript:;" method="post" className='search-wrapper' onSubmit={submitSearch}>
                    <div className='select-filter'>
                        <div className='close-btn' onClick={() => setCloseState(false)}>
                            <i className='icon-cross'></i>
                        </div>
                        <div className={calenderState ? 'calender-block active' : 'calender-block'}>

                            <div className='date-picker'>
                                {/* <DateRange
                                    editableDateInputs={true}
                                    onChange={item => setState([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={state}
                                    weekdayDisplayFormat="EEEEE"
                                    dateDisplayFormat="dd-MM-yyyy"
                                    showClearDate={true}
                                    showDateDisplay={false}
                                    minDate={new Date()}
                                /> */}
                                {/* <div className='clear-date' onClick={() => setState([
                                    {
                                        startDate: new Date(),
                                        endDate: new Date(),
                                        key: 'selection'
                                    }
                                ])}>Clear Dates</div> */}
                                 <DateRange
                                    
                                    editableDateInputs={true}
                                    onChange={handleDateChange}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[dateRange]}
                                    weekdayDisplayFormat="EEEEE"
                                    dateDisplayFormat="dd-MM-yyyy"
                                    showClearDate={true}
                                    showDateDisplay={false}
                                    minDate={new Date()}
                                />
                            </div>
                            <div className='calender-btn' onClick={() => setCalenderState(prev => !prev)}></div>
                        </div>
                        
                        <div className='btn-panel'>
                            <button type="submit" className='update-btn'>Update Results</button>
                        </div>
                    </div>
                </Form>
            }

        </>
    )
}

export default PropertyFilterHolidayLets